import React from "react";
import { Link } from "react-router-dom";

import "./Home.css";

const Home = () => (
  <div className="center">
    <h1>hello.</h1>
    <Link to="/login">
      <button className="pill button-black button">login</button>
    </Link>
  </div>
);
export default Home;
