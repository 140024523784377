/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { useAlert } from "react-alert";

import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("jwt_token");

  const alert = useAlert();

  useEffect(() => {
    if (token) {
      navigate("/posts");
    }
  }, [navigate, token]);

  const login = async () => {
    try {
      const response = await axios.post("/auth/login", {
        username,
        password,
      });

      localStorage.setItem("jwt_token", response.data.token);
      navigate("/posts");
    } catch (err) {
      alert.error("login is incorrect");
    }
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      login();
    }
  };

  return (
    <div className="container login-container">
      <h3>login</h3>
      <input
        type="text"
        placeholder="username"
        value={username}
        onChange={e => {
          setUsername(e.target.value);
        }}
      />
      <input
        type="password"
        placeholder="password"
        value={password}
        onChange={e => {
          setPassword(e.target.value);
        }}
        onKeyPress={handleKeypress}
      />
      <div className="button-container">
        <button onClick={login} className="pill button-black button">
          login
        </button>
        <button
          className="pill button-black button-outline"
          onClick={() => {
            navigate("otp");
          }}
        >
          get otp
        </button>
      </div>
    </div>
  );
};

export default Login;
