/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Modal from "react-modal";

import { PostType } from "../../types/Post";
import "./Posts.css";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "10px",
  },
};

const Post = ({
  post,
  token,
  removePost,
}: {
  post: PostType;
  token: string | null;
  removePost: (id: string) => void;
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(post.title);
  const [currentBody, setCurrentBody] = useState(post.body);
  const [editingTitle, setEditingTitle] = useState(post.title);
  const [editingBody, setEditingBody] = useState(post.body);

  const deletePost = async (id: string) => {
    axios.delete(`/posts/${id}`, {
      headers: {
        "x-access-token": token!,
      },
    });
    removePost(post._id);
  };

  const editPost = async (id: string) => {
    axios.patch(
      `/posts/${id}`,
      {
        title: editingTitle,
        body: editingBody,
      },
      {
        headers: {
          "x-access-token": token!,
        },
      }
    );

    setCurrentTitle(editingTitle);
    setCurrentBody(editingBody);
    setEditing(false);
  };

  const convertTimestampToDate = (timestamp: number) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(timestamp);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };

  const closeModal = () => {
    setConfirmDelete(false);
  };

  return (
    <div className="post">
      {!editing ? (
        <div className="post-hover">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h4 style={{ marginBottom: "0px" }}>{currentTitle}</h4>
            <a
              className="button-clear button-black fade-in-button"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: "20px" }} icon={faXmark} size="lg" />
            </a>
            <a
              className="button-clear button-black fade-in-button"
              onClick={() => {
                setEditing(true);
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faPen} />
            </a>
          </div>
          <p style={{ color: "#9c9c9c" }}>{convertTimestampToDate(post.timestamp)}</p>
          <p>{currentBody}</p>
          <Modal isOpen={confirmDelete} onRequestClose={closeModal} style={customStyles}>
            <div>
              Are you sure you want to delete this post?
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "15px",
                  gap: "10px",
                }}
              >
                <button
                  className="pill button-clear button-black"
                  onClick={() => {
                    deletePost(post._id);
                  }}
                >
                  yes
                </button>
                <button className="pill button-clear button-black" onClick={closeModal}>
                  no
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <>
          <input
            type="text"
            value={editingTitle}
            style={{ fontSize: "22px" }}
            onChange={e => {
              setEditingTitle(e.target.value);
            }}
          />
          <textarea
            value={editingBody}
            style={{ resize: "none", height: "250px" }}
            onChange={e => {
              setEditingBody(e.target.value);
            }}
          />
          <button
            className="pill button-outline button-black"
            onClick={() => {
              setEditing(false);
            }}
          >
            cancel
          </button>
          <button
            className="pill button button-black float-right"
            onClick={() => {
              editPost(post._id);
            }}
          >
            confirm
          </button>
        </>
      )}
    </div>
  );
};

export default Post;
