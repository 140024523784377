import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router";

import "./Add.css";

const Add = () => {
  const [title, setTitle] = useState(localStorage.getItem("title") || "");
  const [body, setBody] = useState(localStorage.getItem("body") || "");
  const [needBody, setNeedBody] = useState(false);
  const [needTitle, setNeedTitle] = useState(false);

  const alert = useAlert();

  const navigate = useNavigate();
  const token = localStorage.getItem("jwt_token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  const submitPost = () => {
    if (!title && !body) {
      alert.error("post requires a title");
      alert.error("post requires a body");
      return;
    }
    if (!title) {
      setNeedTitle(true);
      return;
    }
    if (!body) {
      setNeedBody(true);
      return;
    }
    axios.post(
      "/posts/add",
      { title, body, token: token as string },
      {
        headers: {
          "x-access-token": token as string,
        },
      }
    );

    localStorage.removeItem("title");
    localStorage.removeItem("body");

    navigate("/posts");
  };

  return (
    <div className="container add-container">
      <h3>add post</h3>
      title:
      <input
        type="text"
        value={title}
        onChange={e => {
          setNeedTitle(false);
          localStorage.setItem("title", e.target.value);
          setTitle(e.target.value);
        }}
      />
      body:
      <textarea
        value={body}
        className="body"
        onChange={e => {
          setNeedBody(false);
          localStorage.setItem("body", e.target.value);
          setBody(e.target.value);
        }}
      />
      <button
        className="pill button-black button-outline float-left"
        onClick={() => {
          navigate("/posts");
        }}
      >
        back
      </button>
      <button className="pill button-black button float-right" onClick={submitPost}>
        submit
      </button>
      {needTitle ? (
        <div>
          need a title.
          <br />
        </div>
      ) : (
        <div />
      )}
      {needBody ? <div>need a body.</div> : <div />}
    </div>
  );
};

export default Add;
