import React from "react";
import { useNavigate } from "react-router";

import "./Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="navbar container">
      <h2>burn book.</h2>
      <button className="pill button button-black logout-button" onClick={logout}>
        logout
      </button>
    </div>
  );
};

export default Navbar;
