/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { PostType } from "../../types/Post";
import Navbar from "../Navbar/Navbar";
import Post from "./Post";

const Posts = () => {
  const token = localStorage.getItem("jwt_token");
  const [posts, setPosts] = useState<PostType[]>([]);

  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await axios.post(
        "/posts",
        { token: token as string },
        {
          headers: {
            "x-access-token": token!,
          },
        }
      );
      setPosts(response.data.sort((a: PostType, b: PostType) => b.timestamp - a.timestamp));
    };
    fetchPosts();
  }, [token]);

  const removePost = (id: string) => {
    setPosts(p => posts.filter(post => post._id !== id));
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <button
          className="pill button-outline button-black"
          onClick={() => {
            navigate("/new");
          }}
        >
          add post
        </button>
        {posts.map((post: PostType) => (
          <Post key={post._id} post={post} token={token} removePost={removePost} />
        ))}
      </div>
    </div>
  );
};

export default Posts;
