import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { useAlert } from "react-alert";

import "./OTP.css";

const Verify = () => {
  const [code, setCode] = useState("");
  const [invalidSession, setInvalidSession] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();

  const verifyCode = async () => {
    if (!(state as any).sessionId) {
      setInvalidSession(true);
      return;
    }
    try {
      const response = await axios.post("/auth/otp/verify", {
        sessionId: (state as any).sessionId,
        code,
      });
      if (response.status === 200) {
        localStorage.setItem("jwt_token", response.data);
        navigate("/posts");
      } else {
        alert.error("otp code is incorrect");
      }
    } catch (err) {
      alert.error("otp code is incorrect");
    }
  };
  return (
    <div className="container otp-container">
      <h4>verify otp</h4>
      <input
        type="text"
        placeholder="otp code"
        value={code}
        onChange={e => {
          setCode(e.target.value);
        }}
      />
      <button className="pill button-black button" onClick={verifyCode}>
        submit
      </button>
      {invalidSession ? <p>your session is invalid. please go back and try again.</p> : <div />}
    </div>
  );
};

export default Verify;
