import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";

import "./index.css";

import App from "./App";

const AlertTemplate = ({ style, options, message, close }: any) => (
  <div className="alert" style={style}>
    {options.type === "info" && <FontAwesomeIcon icon={faTriangleExclamation} />}
    {options.type === "success" && ":)"}
    {options.type === "error" && <FontAwesomeIcon icon={faTriangleExclamation} />}
    {message}
    <a onClick={close}>
      <FontAwesomeIcon icon={faXmark} />
    </a>
  </div>
);

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>,
  document.getElementById("root")
);
