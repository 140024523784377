import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAlert } from "react-alert";

import "./OTP.css";

const OTP = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const alert = useAlert();

  const submitUsername = async () => {
    try {
      const response = await axios.post("/auth/otp", {
        username,
      });

      if (response.status === 200) {
        navigate("/login/otp/verify", { state: { sessionId: response.data.sessionId } });
      } else {
        alert.error("username not found");
      }
    } catch (err) {
      alert.error("username not found");
    }
  };

  return (
    <div className="container otp-container">
      <h4>enter username</h4>
      <input
        type="text"
        placeholder="username"
        value={username}
        onChange={e => {
          setUsername(e.target.value);
        }}
      />
      <button className="pill button-black button" onClick={submitUsername}>
        submit
      </button>
    </div>
  );
};

export default OTP;
