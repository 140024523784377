import React from "react";
import axios from "axios";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";

import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Posts from "./components/Posts/Posts";
import OTP from "./components/OTP/OTP";
import Verify from "./components/OTP/Verify";
import Add from "./components/Add/Add";

axios.defaults.baseURL = "https://api.edmundxin.me"

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="login/otp" element={<OTP />} />
      <Route path="login/otp/verify" element={<Verify />} />
      <Route path="posts" element={<Posts />} />
      <Route path="new" element={<Add />} />
    </Routes>
  </BrowserRouter>
);
export default App;
